import React from "react";
import "./style.styl";


export default function NoContent(props) {
    let {synonymList, noContentWidget, fontSize, onClick} = props;
    fontSize = fontSize || "u-fontSize16";
    let synonym = "";
    if (synonymList && synonymList.length > 0) {
        synonym = (
            <span>
            Возможно, вы имели в виду:
                {
                    synonymList.slice(0, 3).map(((item, i) => {
                        const or = (synonymList.length - 1) === i ? "" : <span className="u-paddingLeft5">или</span>;
                         return (
                            <span key={i}>
                                <span key={i}
                                      className="link u-paddingLeft5"
                                      onClick={() => onClick(item)}>
                                    {item.name}
                                </span>
                                {or}
                            </span>
                        );
                    }))
                }
            </span>
        );
    }



    return (
        <div className="u-flexColumn">
            <span className={"u-textColorNormal u-textAlignCenter " + fontSize}>
                No content found<br/> {synonym}
            </span>
            {
                noContentWidget ?
                    <div className="u-marginTop50">{noContentWidget}</div>
                    :
                    null
            }
        </div>
    );
}
