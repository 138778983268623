
export default function buildFacetQuery(facetDataDict) {
    const res = {};
    const aggs = buildAgg(facetDataDict);
    if (aggs) {
        res.aggs = aggs;
    }
    const postFilter = buildPostFilter(facetDataDict);
    if (postFilter) {
        res.post_filter = postFilter;
    }
    return res;

}

function buildPostFilter(facetDataDict) {
    const postFilter = {
        "bool": {
            "filter": [
            ]
        }
    };
    Object.keys(facetDataDict).forEach(key => {
        const facet = facetDataDict[key] || [];
        if (facet.length === 0) {
            return;
        }
        if (facet.length === 1) {
            postFilter.bool.filter.push({nested: buildNestedFilter(key, facet[0])});
            return;
        }
        const should = {
            "bool": {
                "should": [
                ]
            }
        };
        facet.forEach(value => {
            should.bool.should.push({nested: buildNestedFilter(key, value)});
        });
        postFilter.bool.filter.push(should);
    });
    return postFilter;
}

function buildAgg(facetDataDict) {
    const agg = {};
    const general = {
        "filter": {
            "bool": {
                "filter": [
                ]
            }
        },
        "aggs": {
            "agg_string_facet": {
                "nested": {
                    "path": "search_data.string_facet"
                },
                "aggs": {
                    "facet_name": {
                        "terms": {
                            "field": "search_data.string_facet.name"
                        },
                        "aggs": {
                            "facet_value": {
                                "terms": {
                                    "field": "search_data.string_facet.id_value",
                                    "size": 1000
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    Object.keys(facetDataDict).forEach(key => {
        const facet = facetDataDict[key] || [];
        if (facet.length === 0) {
            return;
        }
        const name = "special_facet_" + key;
        agg[name] = buildSpecialAgg(name, key, facetDataDict);

        if (facet.length === 0) {
            return;
        }
        if (facet.length === 1) {
            general.filter.bool.filter.push({nested: buildNestedFilter(key, facet[0])});
            return;
        }
        const should = {
            "bool": {
                "should": [
                ]
            }
        };
        facet.forEach(value => {
            should.bool.should.push({nested: buildNestedFilter(key, value)});
        });
        general.filter.bool.filter.push(should);
    });
    agg["general_facet"] = general;
    return agg;
}




const buildSpecialAgg = (name, key, facetDataDict) => {

    const special = {
        filter: {
            bool: {
                filter: [

                ]
            }
        },
        aggs: {
        }
    };

    Object.keys(facetDataDict).forEach(key_ => {
        if (key_ === key) {
            return;
        }
        const facet = facetDataDict[key_] || [];

        if (facet.length === 0) {
            return;
        }
        if (facet.length === 1) {
            special.filter.bool.filter.push({nested: buildNestedFilter(key_, facet[0])});
            return;
        }
        const should = {
            "bool": {
                "should": [
                ]
            }
        };
        facet.forEach(value_ => {
            should.bool.should.push({nested: buildNestedFilter(key_, value_)});
        });
        //should.bool.should.minimum_should_match = should.bool.should.length
        special.filter.bool.filter.push(should);

    });

    //"terms": {
    //    field: "search_data.string_facet.name"
    //},
    //"filter": {
    //    "bool": {
    //        "filter": [
    //            {nested: buildNestedFilter(key, undefined, true)}
    //        ]
    //    }
    //},
    special.aggs["agg_string_facet"] = {
        "nested": {
            "path": "search_data.string_facet"
        },
        "aggs": {
            "facet_name": {
                "filter": {
                    "term": {
                        "search_data.string_facet.name": key.endsWith("_filter") ? key.replace("_filter", "") : key
                    }
                },
                "aggs": {
                    "facet_value": {
                        "terms": {
                            "size": 1000,
                            "field": "search_data.string_facet.id_value"
                        }
                    }
                }
            }
        }
    };
    return special;
};

const buildNestedFilter = (key, value, noValue) => {
    const nested = {
        query: {
            bool: {
                filter: [
                    {term: {"search_data.string_facet.name": {value: key.endsWith("_filter") ? key.replace("_filter", "") : key}}}
                ]
            }
        },
        path: "search_data.string_facet"
    };
    if (!noValue) {
        const valueFilter = {
            terms: {
                "search_data.string_facet.value_id": [
                    value.value
                ]
            }
        };
        nested.query.bool.filter.push(valueFilter);
    }
    return nested;
};
