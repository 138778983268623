import React, {useEffect, useState} from "react";
import redraft from "redraft";
import "./graf.styl";
import "./caption.styl";
import "./media.styl";
import "./blame.styl";
import "./draft.styl";
import "./style.styl";


const styles = {
  code: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: "\"Inconsolata\", \"Menlo\", \"Consolas\", monospace",
    fontSize: 16,
    padding: 2,
  },
  codeBlock: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: "\"Inconsolata\", \"Menlo\", \"Consolas\", monospace",
    fontSize: 16,
    padding: 20,
  },
};

const inline = {
  BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
  ITALIC: (children, { key }) => <em key={key}>{children}</em>,
  UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
  CODE: (children, { key }) => (
    <span key={key} style={styles.code}>
      {children}
    </span>
  ),
};

const addBreaklines = children => children.map(child => [child, <br />]);

const getList = ordered => (children, { depth, keys }) => (
  <ul key={keys[0]} keys={keys} className="public-DraftStyleDefault-ul" depth={depth} ordered={ordered}>
    {children.map((child, i) => (
      <li key={keys[i]} className="view_graf view_graf--insertunorderedlist  public-DraftStyleDefault-unorderedListItem public-DraftStyleDefault-reset public-DraftStyleDefault-depth0 public-DraftStyleDefault-listLTR">{child}</li>
    ))}
  </ul>
);

const getAtomic = (children, { data, keys }) => data.map(
  (item, i) => <AtomicBlock key={keys[i]} {...data[i]} />,
);

/**
 * Note that children can be maped to render a list or do other cool stuff
 */
const blocks = {
  // Rendering blocks like this along with cleanup results in a single p tag for each paragraph
  // adding an empty block closes current paragraph and starts a new one
  image: (children, options) => {
      const keys = options.keys;
      const text = options.text || [];
      const data = options.data || [];
      return children.map((child, i) => {
          const d = data[i];
          const width = d.aspect_ratio.width;
          const height = d.aspect_ratio.height;
          const ratio = d.aspect_ratio.ratio;
          const direction = d.direction;
          const t = text[i];
          const directionClass = direction === "fill" ? " view_graf--layoutFillWidth" : (direction === "wide" ? " view_sectionLayout--fullWidth" : (direction === "left" ? "view_graf--layoutOutsetLeft" : ""));
          return (
              <figure key={keys[i]} className={"graf view_graf--figure " + directionClass}>
                  <figure>
                      <div className="aspectRatioPlaceholder"
                           style={{maxWidth: width, maxHeight: height}}>

                           <div className="aspect-ratio-fill" style={{paddingBottom: ratio.toString() + "%"}}></div>
                           <img src={d.url}
                                height={height.toString()}
                                width={width.toString()}
                                className="view_graf-image"
                                alt={d.url}/>

                           <div></div>
                       </div>
                       <figcaption className="view_imageCaption public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
                           {
                               child.map(v => {

                                   const isEmpty = v.length === 0;
                                   return (
                                       <span>{isEmpty ? <br/> : v}</span>
                                   );
                               })
                           }
                       </figcaption>
                   </figure>
               </figure>
          );
      });
  },

  unstyled: (children, { keys }) => children.map((child, i) => {
      const isEmpty = child[1].length === 0;
      return (
      <div key={keys[i]} className="graf view_graf--p public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
            {
            isEmpty ?
                <br/>
                :
                child
            }
      </div>
  );}),
  atomic: getAtomic,
  blockquote: (children, { keys }) => (
    <blockquote key={keys[0]}>{addBreaklines(children)}</blockquote>
  ),
  "header-one": (children, { keys }) => children.map((child, i) => (
      <h1 key={keys[i]} className="graf view_graf--h2 public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
         {child}
      </h1>
  )),
  "header-two": (children, { keys }) => children.map((child, i) => (
      <h2 key={keys[i]} className="graf view_graf--h3 public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
         {child}
     </h2>
  )),
  "header-three": (children, { keys }) => children.map((child, i) => (
      <h3 key={keys[i]} className="graf view_graf--h4 public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
         {child}
     </h3>
  )),
  "header-four": (children, { keys }) => children.map((child, i) => <h4 key={keys[i]}>{child}</h4>),
  "header-five": (children, { keys }) => children.map((child, i) => <h5 key={keys[i]}>{child}</h5>),
  "header-six": (children, { keys }) => children.map((child, i) => <h6 key={keys[i]}>{child}</h6>),
  "code-block": (children, { keys }) => (
    <pre key={keys[0]} style={styles.codeBlock}>
      {addBreaklines(children)}
    </pre>
  ),
  "unordered-list-item": getList(),
  "ordered-list-item": getList(true),
};

const entities = {
  LINK: (children, entity, { key }) => (
    <a key={key} href={entity.url}>
      {children}
    </a>
  ),
};


export default function DraftView(props) {
        const {content} = props;
        const [contentState, setContentState] = useState(null);

        useEffect(() => {
            if (content) {
                const value = (typeof content === "string") ? (content ? JSON.parse(content) : null) : null;
                setContentState(value);
            }
        }, [content]);
        if (!contentState) {
            return null;
        }



      if (!content) {
        return null;
      }
      const rendered = redraft(contentState, { inline, blocks, entities });

      if (!rendered) {
        return null;
      }

      return (
        <div className="draft-view">
          {rendered}
        </div>
      );
}
