import React from "react";
import Item from "./Item";
import {PaginationMaterial} from "../../Pagination";
import NoContent from "ecommerce-commons-ui/core/NoContent";
import "./style.styl";


export default function Gallery(props) {
    let {items, loading, noContentWidget, pageTotal, loadingItemsCount, itemComponent, searchSynonymList, filterSelectedDict, facetDataDict, facetDataList, onSelectPagination, onAddFilter, pageSelected, isMobile} = props;
    const withFilter = (props.withFilter === undefined || props.withFilter === true );
    const itemId = props.itemId || "product_id";

    if (loading && items.length === 0) {
        const itemsNew = [];
        for (let i = 0; i < (loadingItemsCount || 12); i++) {
            itemsNew.push({});
        }
        items = itemsNew;
    }

    return (
        <div className="u-sizeFullWidth">
            <div className={"" + (withFilter ? " with-filter" : "") + (isMobile ? " product-mobile-grid" : " u-flex u-flexWrap u-marginTopNegative8 u-marginBottomNegative8 u-marginLeftNegative8 u-marginRightNegative8 product-grid-tmall")}>
                {items.map((item, i)=>{
                    const itemIdValue = item[itemId];
                    const categoryDict = item.category_id_dict || {};
                    if (itemComponent) {
                        return itemComponent({item, props: {...props, item, isLoading: loading, itemId, itemIdValue, categoryDict}});
                    }

                    return (
                        <div key={i} className="item">
                            <Item   {...props}
                                    isLoading={loading}
                                    item={item}
                                    itemId={itemId}
                                    itemIdValue={itemIdValue}
                                    categoryDict={categoryDict}/>
                        </div>
                    );
                })}
                {
                    !loading && items.length === 0 ?
                        <div className="u-sizeFullWidth u-marginTop50 u-marginBottom100 u-flexCenter u-justifyContentCenter u-padding5">
                            <NoContent noContentWidget={noContentWidget} synonymList={searchSynonymList} onClick={(item) => onAddFilter("search", item.name)}/>
                        </div>
                        :
                        null
                }
            </div>
            <div className="u-marginTop20">
                <PaginationMaterial size="large" total={pageTotal} selected={pageSelected} onPage={onSelectPagination}/>
            </div>
        </div>
    );
}
