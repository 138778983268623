import React from "react";
import renderHTML from "react-render-html";
import DraftView from "education-commons-ui/core/DraftView";
import "./style.styl";


export const datePickerOptions = {
    cancelLabel: "Назад",
    clearLabel: "Очистить",
    invalidDateMessage: "Неправильная дата",
    maxDateMessage: "Укажите дату из прошлого",
    okLabel: "Ok",
    todayLabel: "Cегодня"
};


export const preprocessPhone = (v, add) => {
    if (!v) {
        return v;
    }
    if (add) {
        if (!v.startsWith(7)) {
            return  "7" + v;
        }
        return v;
    } else {
        if (v.startsWith(7)) {
            return v.slice(1);
        }
        return v;
    }
};


export const renderText = (text, type, options) => {
    options = options || {};
    if (type === "html") {
        return renderHTML(text || "");
    }
    if (type === "medium_rich") {
        return <DraftView {...options.props || {}} content={text} />;
    }
    return text;
};



export const dateOrNull = (d) => d && !d.includes("1970-01-01") ? new Date(d) : null;

export const maxText = (t, n) => t ? (t.length > n ? t.slice(0, n) + "..." : t) : "";


export const questionIconDict = {
    "multiple_choice": (
        <span className="question-type-icon" style={{backgroundColor: "#4fa9b3"}}>
            <svg width="14" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.7072.707124L5.00008 9.41423.292969 4.70712c.781051-.78104 2.047381-.78104 2.828431 0L5.00008 6.5858 10.8788.707124c.781-.7810482 2.0473-.7810482 2.8284 0z"></path></svg>
        </span>
    ),
    "phone_number": (
        <span className="question-type-icon" style={{backgroundColor: "#3ec469"}}>
            <svg class="SVGInline-svg" width="14" height="14" fill-rule="evenodd" xmlns="http://www.w3.org/2000/svg"><path d="M13.06 9l-2.478-1.437-1.79 3.053a.962.962 0 0 1-1.282.361A10.551 10.551 0 0 1 2.975 6.45a.946.946 0 0 1 .373-1.274l3.081-1.76L5.003.953A1.906 1.906 0 0 0 3.837.064 1.925 1.925 0 0 0 2.381.26l-1.416.818a1.892 1.892 0 0 0-.957 1.826 12.333 12.333 0 0 0 3.535 7.483 12.471 12.471 0 0 0 7.489 3.597 1.924 1.924 0 0 0 1.856-.951l.852-1.455A1.893 1.893 0 0 0 13.06 9z"></path></svg>
        </span>
    ),
    "short_text": (
        <span className="question-type-icon" style={{backgroundColor: "#ffba49"}}>
            <svg class="SVGInline-svg" width="14" height="6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h7v2H0V0zM9 0h5v2H9V0zM0 4h3v2H0V4zM5 4h9c0 1.10457-.8954 2-2 2H5V4z"></path></svg>
        </span>
    ),
    "long_text": (
        <span className="question-type-icon" style={{backgroundColor: "#e26c5a"}}>
            <svg class="SVGInline-svg" width="14" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8h6c0 1.10457-.8954 2-2 2H8V8zM0 8h6v2H0V8zM5 4h9v2H5V4zM0 4h3v2H0V4zM10 0h4v2h-4V0zM0 0h8v2H0V0z"></path></svg>
        </span>
    ),
    "custom_answer": (
        <span className="question-type-icon" style={{backgroundColor: "#e26c5a"}}>
            <svg class="SVGInline-svg" width="14" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8h6c0 1.10457-.8954 2-2 2H8V8zM0 8h6v2H0V8zM5 4h9v2H5V4zM0 4h3v2H0V4zM10 0h4v2h-4V0zM0 0h8v2H0V0z"></path></svg>
        </span>
    ),
    "custom_answer_long": (
        <span className="question-type-icon" style={{backgroundColor: "#e26c5a"}}>
            <svg class="SVGInline-svg" width="14" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8h6c0 1.10457-.8954 2-2 2H8V8zM0 8h6v2H0V8zM5 4h9v2H5V4zM0 4h3v2H0V4zM10 0h4v2h-4V0zM0 0h8v2H0V0z"></path></svg>
        </span>
    ),
    "statement": (
        <span className="question-type-icon" style={{backgroundColor: "#93ae89"}}>
            <svg class="SVGInline-svg" width="14" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 0C8.8955 0 8 0.8955 8 2V6H12V10.5C13.1045 10.5 14 9.6045 14 8.5V0H10Z"></path><path d="M0 2V6H4V10.5C5.1045 10.5 6 9.6045 6 8.5V0H2C0.8955 0 0 0.8955 0 2Z"></path></svg>
        </span>
    ),
    "picture_choice": (
        <span className="question-type-icon" style={{backgroundColor: "#9f86c4"}}>
            <svg class="SVGInline-svg" width="16" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.583 9.825C13.101 8.9925 11.8995 8.9925 11.4175 9.825L10 12.2725L6.583 6.37C6.101 5.5375 4.8995 5.5375 4.4175 6.37L0 14H16L13.583 9.825Z"></path><path d="M11.5 5C12.8807 5 14 3.88071 14 2.5C14 1.11929 12.8807 0 11.5 0C10.1193 0 9 1.11929 9 2.5C9 3.88071 10.1193 5 11.5 5Z"></path></svg>
        </span>
    ),
    "yes_no": (
        <span className="question-type-icon" style={{backgroundColor: "#bf395b"}}>
            <svg class="SVGInline-svg" width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.13086.368561C3.71236.368561.130859 3.95006.130859 8.36856c0 4.41854 3.581501 8.00004 8.000001 8.00004 4.41854 0 8.00004-3.5815 8.00004-8.00004 0-4.4185-3.5815-7.999999-8.00004-7.999999zm0 14.500039c-3.584 0-6.5-2.916-6.5-6.50004 0-3.584 2.916-6.5 6.5-6.5V14.8686z"></path></svg>
        </span>
    ),
    "email": (
        <span className="question-type-icon" style={{backgroundColor: "#397685"}}>
            <svg class="SVGInline-svg" width="16" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.898 7.182C9.391 7.689 8.717 7.968 8 7.968C7.2825 7.968 6.6085 7.6885 6.102 7.1815L0 1.08V10C0 11.1045 0.8955 12 2 12H14C15.1045 12 16 11.1045 16 10V1.08L9.898 7.182Z"></path><path d="M8 6.505C8.3165 6.505 8.633 6.3875 8.8685 6.1525L15.0205 0H0.9795L7.1315 6.1525C7.367 6.3875 7.6835 6.505 8 6.505Z"></path></svg>
        </span>
    ),
    "opinion_scale": (
        <span className="question-type-icon" style={{backgroundColor: "#73a4be"}}>
            <svg class="SVGInline-svg" width="14" height="13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0c1.1045 0 2 .8955 2 2v11h-4V0h2zM4 10.5V13H0V8.5h2c1.1045 0 2 .8955 2 2zM9 6v7H5V4h2c1.1045 0 2 .8955 2 2z"></path></svg>
        </span>
    ),
    "rating": (
        <span className="question-type-icon" style={{backgroundColor: "#f3cd58"}}>
            <svg class="SVGInline-svg" width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.64345.467259L8.98398 4.5934h4.33832c.6554 0 .9275.83827.3977 1.22371l-3.5097 2.55024 1.3405 4.12615c.2026.623-.5105 1.1414-1.0408.7564l-3.50969-2.5506-3.50969 2.5502c-.53026.385-1.24339-.133-1.04083-.7565l1.34053-4.12609-3.510132-2.5498C-.250072 5.4321.022496 4.5934.677883 4.5934H5.01622L6.35674.467259c.20257-.623012 1.08458-.623012 1.28671 0z"></path></svg>
        </span>
    ),
    "date": (
        <span className="question-type-icon" style={{backgroundColor: "#f09f97"}}>
            <svg class="SVGInline-svg" width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 2V0C11.6715 0 11 0.6715 11 1.5V2H3V1.5C3 0.6715 2.3285 0 1.5 0V2H0V14C0 15.1045 0.8955 16 2 16H12C13.1045 16 14 15.1045 14 14V2H12.5ZM12.5 14C12.5 14.2755 12.2755 14.5 12 14.5H2C1.7245 14.5 1.5 14.2755 1.5 14V6H12.5V14Z"></path> <path d="M9.25 10C9.94036 10 10.5 9.44036 10.5 8.75C10.5 8.05964 9.94036 7.5 9.25 7.5C8.55964 7.5 8 8.05964 8 8.75C8 9.44036 8.55964 10 9.25 10Z"></path></svg>
        </span>
    ),
    "number": (
        <span className="question-type-icon" style={{backgroundColor: "#df4f78"}}>
            <svg class="SVGInline-svg" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 6H14.217C15.202 6 16 5.1045 16 4H12V0C10.8955 0 10 0.798 10 1.783V4H6V0C4.8955 0 4 0.798 4 1.783V4H1.783C0.798 4 0 4.8955 0 6H4V10H1.783C0.798 10 0 10.8955 0 12H4V16C5.1045 16 6 15.202 6 14.217V12H10V16C11.1045 16 12 15.202 12 14.217V12H14.217C15.202 12 16 11.1045 16 10H12V6ZM6 10V6H10V10H6Z"></path></svg>
        </span>
    ),
    "dropdown": (
        <span className="question-type-icon" style={{backgroundColor: "#58457a"}}>
            <svg class="SVGInline-svg" width="12" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00008 3.58581L3.12139 0.707124C2.34035 -0.0739251 1.07402 -0.0739234 0.292969 0.707125L6.00008 6.41423L11.7072 0.707125C10.9261 -0.0739234 9.6598 -0.0739234 8.87876 0.707125L6.00008 3.58581Z"></path></svg>
        </span>
    ),
    "file_upload": (
        <span className="question-type-icon" style={{backgroundColor: "#3c6996"}}>
            <svg class="SVGInline-svg" width="16" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.9815 2.5455L6.524 1.6305C6.1775 0.9375 5.469 0.5 4.6945 0.5H0.504V2.5455H0.5V12C0.5 12.8285 1.1715 13.5 2 13.5H14C14.8285 13.5 15.5 12.8285 15.5 12V2.5455H6.9815Z"></path></svg>
        </span>
    ),
    "website": (
        <span className="question-type-icon" style={{backgroundColor: "#bac26c"}}>
            <svg class="SVGInline-svg" width="16" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.48061 4.9998C3.48061 5.55229 3.9281 5.99978 4.48059 5.99978H11.4999C12.0524 5.99978 12.4999 5.55229 12.4999 4.9998C12.4999 4.44731 12.0524 3.99982 11.4999 3.99982H4.48059C3.9281 3.99982 3.48061 4.44731 3.48061 4.9998Z"></path><path d="M6.99983 7.49974H4.61158C3.30661 7.49974 2.13914 6.54826 2.01265 5.24979C1.86715 3.75933 3.03912 2.49986 4.50008 2.49986H6.99983C6.99983 1.39532 6.10462 0.499908 5.00007 0.499908H4.65408C2.25714 0.499908 0.16469 2.30836 0.00969335 4.70031C-0.159803 7.32124 1.91565 9.49969 4.50008 9.49969H5.00007C6.10461 9.49969 6.99983 8.60428 6.99983 7.49974Z"></path><path d="M8.99048 7.49974H11.3787C12.6837 7.49974 13.8512 6.54826 13.9777 5.24979C14.1232 3.75933 12.9512 2.49986 11.4902 2.49986H8.99048C8.99048 1.39532 9.88569 0.499908 10.9902 0.499908H11.3362C13.7332 0.499908 15.8256 2.30836 15.9806 4.70031C16.1501 7.32124 14.0747 9.49969 11.4902 9.49969H10.9902C9.88569 9.49969 8.99048 8.60428 8.99048 7.49974Z"></path></svg>
        </span>
    ),
    "location": (
        <span className="question-type-icon" style={{backgroundColor: "#bac26c"}}>
            <svg class="SVGInline-svg" width="16" height="10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.48061 4.9998C3.48061 5.55229 3.9281 5.99978 4.48059 5.99978H11.4999C12.0524 5.99978 12.4999 5.55229 12.4999 4.9998C12.4999 4.44731 12.0524 3.99982 11.4999 3.99982H4.48059C3.9281 3.99982 3.48061 4.44731 3.48061 4.9998Z"></path><path d="M6.99983 7.49974H4.61158C3.30661 7.49974 2.13914 6.54826 2.01265 5.24979C1.86715 3.75933 3.03912 2.49986 4.50008 2.49986H6.99983C6.99983 1.39532 6.10462 0.499908 5.00007 0.499908H4.65408C2.25714 0.499908 0.16469 2.30836 0.00969335 4.70031C-0.159803 7.32124 1.91565 9.49969 4.50008 9.49969H5.00007C6.10461 9.49969 6.99983 8.60428 6.99983 7.49974Z"></path><path d="M8.99048 7.49974H11.3787C12.6837 7.49974 13.8512 6.54826 13.9777 5.24979C14.1232 3.75933 12.9512 2.49986 11.4902 2.49986H8.99048C8.99048 1.39532 9.88569 0.499908 10.9902 0.499908H11.3362C13.7332 0.499908 15.8256 2.30836 15.9806 4.70031C16.1501 7.32124 14.0747 9.49969 11.4902 9.49969H10.9902C9.88569 9.49969 8.99048 8.60428 8.99048 7.49974Z"></path></svg>
        </span>
    ),
    "start_screen": (
        <span className="question-type-icon" style={{backgroundColor: "#bbd395"}}>
            <svg class="SVGInline-svg" width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H7.5V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2Z"></path><path d="M11 1H9V13H11V1Z"></path><path d="M14 3H12.5V11H14V3Z"></path></svg>
        </span>
    ),
    "complete_screen": (
        <span className="question-type-icon" style={{backgroundColor: "#96cedc"}}>
            <svg class="SVGInline-svg" width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 2V12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H6.5V0H12C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2Z"></path> <path d="M3 1H5V13H3V1Z"></path><path d="M0 3H1.5V11H0V3Z"></path></svg>
        </span>
    ),
    "signature": (
        <span className="question-type-icon" style={{backgroundColor: "#bbd395"}}>
            <svg class="SVGInline-svg" width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H7.5V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2Z"></path><path d="M11 1H9V13H11V1Z"></path><path d="M14 3H12.5V11H14V3Z"></path></svg>
        </span>
    ),
};

export const questionNameDict = {
    "multiple_choice": "Multiple Choice",
    "phone_number": "Phone Number",
    "short_text": "Short Text",
    "long_text": "Long Text",
    "custom_answer": "Custom Answer",
    "custom_answer_long": "Custom Answer (Long)",
    "statement": "Statement",
    "picture_choice": "Picture Choice",
    "yes_no": "Yes No",
    "email": "Email",
    "opinion_scale": "Opinion Scale",
    "rating": "Rating",
    "date": "Date",
    "number": "Number",
    "dropdown": "Dropdown",
    "file_upload": "File Upload",
    "website": "Website",
    "location": "Location",
    "signature": "Signature",
    "start_screen": "Start Screen",
    "complete_screen": "Complete Screen"
};


export const questionTypeList = [
    //"multiple_choice",
    "custom_answer",
    "custom_answer_long",
    "dropdown",
    "yes_no",
    "short_text",
    "phone_number",
    "email",
    //"statement",
    //"picture_choice",
    //"opinion_scale",
    //"rating",
    "date",
    "number",
    "location",
    "signature",
    //"file_upload",
    //"website"
];
