import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Pagination from "material-ui-flat-pagination";
import "./style.styl";


export function PaginationSimple(props) {
    const {className, classNameButton, classNameIcon, selected, disabled, total} = props;

    const onPage = (isLeft) => {
        let page = selected || 1;
        if (isLeft) {
            page = page - 1;
        } else {
            page = page + 1;
        }
        props.onPage(page);
    };

    if (!total || total === 1) {
        return <div></div>;
    }

    return (
        <div className={"u-flexCenter " + (className || "") }>
            <IconButton disabled={(selected <= 1) || disabled}
                        onClick={() => onPage(true)}
                        className={"u-padding5 u-marginRight5 " + (classNameButton || "")}>
                <ChevronLeft className={classNameIcon}/>
            </IconButton>
            {selected} / {total}
            <IconButton disabled={selected === total || disabled}
                        onClick={() => onPage(false)}
                        className={"u-padding5 u-marginLeft5 " + (classNameButton || "")}>
                <ChevronRight className={classNameIcon}/>
            </IconButton>
        </div>
    );
}


export class PaginationWidget extends React.Component{

    range = (size, startAt = 0) => {
        return [...Array(size).keys()].map(i => i + startAt);
    }

    render(){
        let {selected, total, onPage, isMobile} = this.props;
        selected = selected.toString();
        if (total <= 1) {
            return null;
        }
        return (
            <div className={"ant-pagination u-xs-overflowScrollX u-overflowHiddenY u-paddingVertical10" + (isMobile ? " u-noWrap" : "")}>
                {
                    this.range(total).map((v, i) => {
                        const page = i + 1;
                        const selectedClass = page.toString() === selected ? " selected" : "";
                        return (
                            <div    key={i}
                                    className={"item u-marginVertical10" + selectedClass}
                                    onClick={() => onPage(page)}>
                                {page}
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}



export function PaginationMaterial(props) {
    let {selected, total, onPage, size, isMobile} = props;

    if (total <= 1) {
        return null;
    }

    return (
        <Pagination
                      limit={1}
                      size={size}
                      offset={selected - 1}
                      total={total}
                      previousPageLabel={<ChevronLeft/>}
                      nextPageLabel={<ChevronRight/>}
                      onClick={(e, offset) => onPage(offset + 1)}
                    />
    );
}
