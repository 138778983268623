import React, {useEffect, useState} from "react";
import queryString from "query-string";
import IconButton from "@material-ui/core/IconButton";
import ViewModule from "@material-ui/icons/ViewModule";
import ViewList from "@material-ui/icons/ViewList";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Tooltip from "@material-ui/core/Tooltip";
import Gallery from "./Gallery";
import ListView from "./ListView";
import TableWidget from "./Table";
import {Helmet} from "react-helmet";
import SearchWithAutocompleteWidget from "ecommerce-commons-ui/core/SearchWithAutocompleteWidget";
import BreadcrumbsWidget from "ecommerce-commons-ui/core/BreadcrumbsWidget";
import {getFromLS, saveToLS} from "commons-ui/core/utils/index";
import FilterListWidget from "ecommerce-commons-ui/core/FilterListWidget";
import FilterWidgetMobile from "ecommerce-commons-ui/core/FilterWidgetMobile";
import FilterWidget from "ecommerce-commons-ui/core/FilterWidget";
import CategoryWidget from "ecommerce-commons-ui/core/CategoryWidget";
import SortWidget from "ecommerce-commons-ui/core/SortWidget";
import buildFacetQuery from "ecommerce-commons-ui/core/utils/query";
import InfoBlock from "./components/InfoBlock";
import CategoryBlock from "./components/CategoryBlock";
import ArticleBlock from "./components/ArticleBlock";
import ContentPreview from "./ContentPreview";
import "./style.styl";


export default function ProductGallery(props) {
    const {category_widget, ecommerceStoreId, withDescriptionData, withCategoryBlock, withInfoBlock, withArticleBlock, isBrandPage, ecommerceStoreGroupId, mediaPath, location, returnNull, noImage, apiPath, isMobile, dispatch} = props;

    const [itemData, setItemData] = useState({itemList: [], pageTotal: 0, itemTotal: 0, pageSize: 25, searchSynonymList: []});
    const {itemList, pageTotal, itemTotal, pageSize, searchSynonymList} = itemData;
    const [facetData, setFacetData] = useState({facetDataDict: {}, facetDataList: []});
    const {facetDataDict, facetDataList} = facetData;
    const [filterSelectedDict, setFilterSelectedDict] = useState({});
    const [descriptionData, setDescriptionData] = useState({});
    const [productDisplay, setProductDisplay] = useState((getFromLS("settings") || {}).productDisplay || "gallery");
    const [loading, setLoading] = useState(true);

    const categorySelected = ((category_widget.categoryFlatDict || {})[filterSelectedDict.category] || {});
    const isCategoryPreview = (categorySelected.product_category || {}).type === "preview";

    const request = requestProductListTemplate({setItemData, setFacetData, apiPath, ecommerceStoreId, ecommerceStoreGroupId, loading, setLoading});

    const onProductDescriptionOpen = (id) => {
        const params = queryString.parse(location.search);
        params["show_product"] = id;
        dispatch(location.pathname + "?"+ queryString.stringify(params));
    };

    const onAddFilter = onAddFilterTemplate({location, dispatch, request, setFilterSelectedDict});
    const onDeleteFilter = onDeleteFilterTemplate({location, dispatch, request, setFilterSelectedDict});
    const onDeleteFilterMany = onDeleteFilterManyTemplate({location, dispatch, request, setFilterSelectedDict});
    const onResetFacetFilters = onResetFacetFiltersTemplate({location, onDeleteFilterMany});

    const onDescriptionData = onDescriptionDataTemplate({apiPath, setDescriptionData});

    const onLocationChange = onLocationChangeTemplate({location, loading, request, filterSelectedDict, setFilterSelectedDict});


    const onChangeProductDisplay = (productDisplay) => {
        const settings = getFromLS("settings") || {};
        settings.productDisplay = productDisplay;
        saveToLS("settings", settings);
        setProductDisplay(productDisplay);
    };

    useEffect(() => {
        if (returnNull || isCategoryPreview) {
            return;
        }
        const filterSelectedDict = queryString.parse(location.search);
        if (location.search) {
            setFilterSelectedDict(filterSelectedDict);
        }
        request(filterSelectedDict);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (returnNull) {
            return;
        }
        onLocationChange();
    }, [location.search]);

    if (withDescriptionData) {
        useEffect(() => {
            if (!isBrandPage && filterSelectedDict.category) {
                onDescriptionData(filterSelectedDict.category, "category");
            }
        }, [filterSelectedDict.category]);

        useEffect(() => {
            if (isBrandPage && filterSelectedDict.manufacturer_filter) {
                onDescriptionData(filterSelectedDict.manufacturer_filter, "manufacturer");
            }
        }, [filterSelectedDict.manufacturer_filter]);
    }

    //render
    const categoryParentList = category_widget.categoryParentDict[filterSelectedDict.category] || [];

    const breadcrumbs = (categoryParentList).map((v, i) => {
        return {name: v.product_category.name, link: () => onAddFilter("category", v.product_category.product_category_id)};
    });
    breadcrumbs.unshift({name: "Главная", link: () => onResetFacetFilters({resetAll: true})});

    let categoryPreviewList = [];
    if (categoryParentList.length > 0) {
        const parentId = (categoryParentList[categoryParentList.length - 1].product_category || {}).parent_id || "main";
        categoryPreviewList = category_widget.categoryDict[parentId] || [];
    } else {
        categoryPreviewList = category_widget.categoryDict["main"] || [];
    }

    let categoryChildPreviewList = category_widget.categoryDict[filterSelectedDict.category] || [];
    if (categoryChildPreviewList.length === 0) {
        categoryChildPreviewList = categoryPreviewList;
    }


    const categoryWidget = (
        <CategoryWidget {...props}
                        selected={filterSelectedDict.category}
                        type={isMobile ? "drawer" : "dropdown"}
                        onSelect={(itemIdValue) => onAddFilter("category", itemIdValue)}/>
    );


    const filterWidgetMobile = (
        <div className="u-marginLeft10">
            <FilterWidgetMobile {...props}
                                selectedDict={filterSelectedDict}
                                categoryPreviewList={categoryPreviewList}
                                facetList={facetDataList}
                                facetDict={facetDataDict}
                                onAddFilter={onAddFilter}
                                loading={loading}
                                onDeleteFilter={onDeleteFilter}
                                onResetFacetFilters={onResetFacetFilters}/>
        </div>
    );

    if (returnNull) {
        return (
            <div className="u-flex u-justifyContentSpaceBetween u-xs-flexColumn u-marginTop10">
                <div className="u-flexCenter u-xs-flexColumnReverse u-justifyContentCenter u-marginBottom12 u-xs-margin0">
                    <div className="u-xs-sizeFullWidth">{categoryWidget}</div>
                    <div className="u-marginRight10 u-xs-marginHorizontal0 u-xs-sizeFullWidth u-marginVertical12">
                        <SearchWithAutocompleteWidget {...props} value={filterSelectedDict.search || ""} onChange={() => null} onSearch={(value) => onAddFilter("search", value)}/>
                    </div>
                </div>
            </div>
        );
    }

    const content = (
        <div className={"u-flex" + (isMobile ? " u-marginTop5" : " u-marginTop15")}>
            {
                isMobile ?
                    null
                    :
                    <div className="u-minWidth200 u-maxWidth200 u-marginRight15">
                        <div className="filter-left-widget u-backgroundWhite u-borderRadius2 u-paddingHorizontal15 u-paddingBottom0">
                            <FilterWidget   {...props}
                                            loading={loading}
                                            selectedDict={filterSelectedDict}
                                            facetList={facetDataList}
                                            facetDict={facetDataDict}
                                            onAddFilter={onAddFilter}
                                            onDeleteFilter={onDeleteFilter}
                                            onResetFacetFilters={onResetFacetFilters}
                                            categoryPreviewList={categoryPreviewList}/>
                        </div>
                    </div>
            }
            <div className="u-sizeFullWidth">
                {
                    withCategoryBlock ?
                        <div>
                            <CategoryBlock  loading={loading}
                                            selected={filterSelectedDict.category}
                                            onSelect={(id) => onAddFilter("category", id)}
                                            categoryList={categoryChildPreviewList}
                                            isMobile={isMobile}
                                            dispatch={dispatch}/>
                        </div>
                        :
                        null
                }

                {
                    withInfoBlock ?
                        <div>
                            <InfoBlock  loading={loading}
                                        descriptionData={descriptionData}
                                        dispatch={dispatch}/>
                        </div>
                        :
                        null
                }

                {
                    (
                        (productDisplay || "gallery") === "gallery" ?
                            <Gallery      {...props}
                                          items={itemList}
                                          itemId="product_id"
                                          modelName="product"
                                          loading={loading}
                                          facetDataDict={facetDataDict}
                                          facetDataList={facetDataList}
                                          filterSelectedDict={filterSelectedDict}
                                          onAddFilter={onAddFilter}
                                          onDeleteFilter={onDeleteFilter}
                                          isImagesCover={descriptionData.is_images_cover}
                                          imagesHeight={descriptionData.images_height}
                                          isImagesVertical={descriptionData.is_images_vertical}
                                          onResetFacetFilters={onResetFacetFilters}
                                          categoryPreviewList={categoryPreviewList}
                                          pageTotal={pageTotal || 0}
                                          searchSynonymList={searchSynonymList}
                                          onItemClick={onProductDescriptionOpen}
                                          pageSelected={filterSelectedDict.page || 1}
                                          onSelectPagination={(page) => onAddFilter("page", page)}/>
                            :
                            (
                                isMobile ?
                                        <ListView {...props}
                                                  items={itemList}
                                                  itemId="product_id"
                                                  modelName="product"
                                                  facetDataDict={facetDataDict}
                                                  facetDataList={facetDataList}
                                                  loading={loading}
                                                  onAddFilter={onAddFilter}
                                                  onDeleteFilter={onDeleteFilter}
                                                  onResetFacetFilters={onResetFacetFilters}
                                                  categoryPreviewList={categoryPreviewList}
                                                  filterSelectedDict={filterSelectedDict}
                                                  onItemClick={onProductDescriptionOpen}
                                                  pageTotal={pageTotal || 0}
                                                  searchSynonymList={searchSynonymList}
                                                  pageSelected={filterSelectedDict.page || 1}
                                                  onSelectPagination={(page) => onAddFilter("page", page)}/>
                                              :

                                              <TableWidget  {...props}
                                                            items={itemList}
                                                            itemId="product_id"
                                                            modelName="product"
                                                            facetDataDict={facetDataDict}
                                                            facetDataList={facetDataList}
                                                            loading={loading}
                                                            onAddFilter={onAddFilter}
                                                            onDeleteFilter={onDeleteFilter}
                                                            onResetFacetFilters={onResetFacetFilters}
                                                            categoryPreviewList={categoryPreviewList}
                                                            filterSelectedDict={filterSelectedDict}
                                                            onItemClick={onProductDescriptionOpen}
                                                            pageTotal={pageTotal || 0}
                                                            pageSize={pageSize}
                                                            itemTotal={itemTotal}
                                                            searchSynonymList={searchSynonymList}
                                                            hasFilters={Object.keys(filterSelectedDict).length}
                                                            pageSelected={filterSelectedDict.page || 1}
                                                            onSelectPagination={(page) => onAddFilter("page", page)}/>
                            )
                    )
                }
                {
                    withArticleBlock ?
                        <div>
                            <ArticleBlock loading={loading} descriptionData={descriptionData} dispatch={dispatch}/>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );

    return (
        <div>
            {
                withDescriptionData && (isBrandPage || filterSelectedDict.category) ?
                    <Helmet>
                        {
                                descriptionData.seo_title || descriptionData.name ?
                                    <title>{descriptionData.seo_title || (descriptionData.name ? descriptionData.name.slice(0, 100) : "")}</title>
                                    :
                                    null
                        }

                        {
                                descriptionData.seo_description || descriptionData.short_description || descriptionData.description ?
                                    <meta name="description" content={descriptionData.seo_description || (descriptionData.short_description ? descriptionData.short_description.slice(0, 180) : "") || (descriptionData.description ? descriptionData.description.slice(0, 180) : "")}/>
                                    :
                                    null
                        }

                        {
                            descriptionData.seo_tags ?
                                <meta name="keywords" content={descriptionData.seo_tags}/>
                                :
                                null
                        }

                        <meta property="og:title" content={descriptionData.seo_og_title || (descriptionData.name ? descriptionData.name.slice(0, 60) : "") || descriptionData.seo_title || "Категория товаров"}/>
                        <meta property="og:description" content={descriptionData.seo_og_description || descriptionData.seo_description || (descriptionData.short_description ? descriptionData.short_description.slice(0, 180) : "") || (descriptionData.description ? descriptionData.description.slice(0, 180) : "") || "Купить товары в интернет магазине. Отзывы, описание, категории"}/>
                        {
                            descriptionData.seo_og_image ?
                                <meta property="og:image" content={mediaPath + descriptionData.seo_og_image}/>
                                :
                                null
                        }
                        <meta property="loaded"/>
                    </Helmet>
                    :
                    null
            }

            <div className="u-flex u-justifyContentSpaceBetween u-xs-flexColumn u-marginTop10">
                <div className="u-flexCenter u-xs-flexColumn u-justifyContentCenter u-marginBottom12 u-xs-margin0">
                    {isMobile ? null : categoryWidget}
                    <div className="u-marginRight10 u-xs-marginHorizontal0 u-xs-sizeFullWidth">
                        <SearchWithAutocompleteWidget {...props} value={filterSelectedDict.search || ""} onChange={() => null} onSearch={(value) => onAddFilter("search", value)}/>
                    </div>
                    <div className="u-flexCenter u-noWrap">
                        <span className="u-fontSize10 u-textColorNormal u-xs-hide">Всего: {itemTotal}</span>
                    </div>
                    <div className="u-sizeFullWidth u-flexCenter u-marginTop10 u-justifyContentSpaceBetween">

                    </div>
                </div>
                <div className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom10">
                    <div className="u-flexCenter">{isMobile ? categoryWidget : null}{isMobile && !isCategoryPreview ? filterWidgetMobile : null}</div>
                    <div className="u-flexCenter u-marginRightNegative10">
                        <SortWidget tooltip="Сортировка"
                                    iconButtonClassName={""}
                                    mode={isMobile ? "" : "button"}
                                    selected={filterSelectedDict.sort}
                                    onSelect={(id) => onAddFilter("sort", id)}/>
                        {
                            isMobile ?
                                null
                                :
                                (
                                    productDisplay === "gallery" ?
                                        <Tooltip title="Таблица">
                                            <IconButton className="u-opacity80"
                                                        onClick={() => onChangeProductDisplay("table")}>
                                                <ViewList/>
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Галерея">
                                            <IconButton className="u-opacity80"
                                                        onClick={() => onChangeProductDisplay("gallery")}>
                                                <ViewModule/>
                                            </IconButton>
                                        </Tooltip>
                                )
                        }
                    </div>
                </div>

            </div>

            <div className="u-flexCenter u-xs-flexColumnTop u-paddingBottom2 u-paddingTop2">
                <BreadcrumbsWidget items={breadcrumbs} dispatch={dispatch}/>
                {
                    isMobile ?
                        <div className="u-marginTop10">
                            <FilterListWidget
                                          itemsDict={filterSelectedDict}
                                          returnNull
                                          dispatch={dispatch}
                                          onDelete={onDeleteFilter}/>
                        </div>
                        :
                        null
                }
            </div>

            {
                isCategoryPreview ?
                    <div className={"" + (isMobile ? " u-marginTop5" : " u-marginTop15")}>
                        {
                            withCategoryBlock ?
                                <div>
                                    <CategoryBlock  loading={loading}
                                                    selected={filterSelectedDict.category}
                                                    onSelect={(id) => onAddFilter("category", id)}
                                                    categoryList={categoryChildPreviewList}
                                                    isMobile={isMobile}
                                                    dispatch={dispatch}/>
                                </div>
                                :
                                null
                        }
                        {
                            withInfoBlock ?
                                <div>
                                    <InfoBlock  loading={loading}
                                                descriptionData={descriptionData}
                                                dispatch={dispatch}/>
                                </div>
                                :
                                null
                        }
                        <ContentPreview {...props} categorySelected={filterSelectedDict.category}/>

                            {
                                withArticleBlock ?
                                    <div>
                                        <ArticleBlock loading={loading} descriptionData={descriptionData} dispatch={dispatch}/>
                                    </div>
                                    :
                                    null
                            }
                    </div>
                    :
                    content
            }
        </div>
    );
}


export const scrollTopIf = (paramsList) => {
    let isScrollTop = false;
    paramsList.forEach(key => {
        if (key === "category" || key === "page" || key.endsWith("_filter")) {
            isScrollTop = true;
        }
    });
    if (isScrollTop) {
        window.scrollTo(0, 0);
    }
};



export const requestProductListTemplate = (props) => (options) => {
    props.setLoading ? props.setLoading(true) : null;
    options = options || {};
    const ecommerceStoreGroupId = props.ecommerceStoreGroupId || "";
    const ecommerceStoreId = props.ecommerceStoreId || "";
    const page = options.page || 1;
    const pageSize = props.pageSize || 24;
    const sort = options.sort;
    const facetSelectedDict = {};

    if (!props.noFacet) {
        Object.keys(options).forEach(key => {
            if (key.endsWith("_filter")) {
                const values = options[key].split(";").filter(v => !!v).map(v => ({value: v}));
                if (values.length !== 0) {
                        facetSelectedDict[key] = values;
                }
            }
        });
    }
    const query = {
        "from" : props.from || ((page - 1 || 0) * pageSize),
        "size": pageSize,
        "sort": props.createSortQuery ? props.createSortQuery(sort, ecommerceStoreId, {isSearch: !!options.search}) : createSortQuery(sort, ecommerceStoreId, {isSearch: !!options.search}),
        "_source" : props.source || ["search_result_data"],
        "query": {
            "bool": {
                "must": [
                ],
                "filter": [
                ],
                "must_not" : [
                ]
            }
        },
        ...(props.noFacet ? {} : buildFacetQuery(facetSelectedDict))
    };

    if (ecommerceStoreGroupId) {
        query.query.bool.filter.push({ "term":  { "search_data.ecommerce_store_group_id": ecommerceStoreGroupId }});
    }


    if (props.withUserAuth) {
        query.query.bool.filter.push("$authTerm");
    }

    if (props.getFilterQuery) {
        (props.getFilterQuery() || []).forEach((item, i) => {
            query.query.bool.filter.push(item);
        });
    }

    if (!props.showHidden) {
        query.query.bool.must_not.push({ "term":  { "hidden.id_list": props.hiddenDirect ? "default" : ecommerceStoreId }});
    }

    if (props.ecommerceStoreId) {
        query.query.bool.filter.push({ "term":  { "search_data.ecommerce_store_id": ecommerceStoreId }});
    }
    const search = options.search || "";
    if (search) {
        const searchQuery = {
            "query_string": {
                "query": ("*" + search.toLowerCase().replace("%", "") + "*"),
                "default_field": "search_data.full_text"
            }
        };
        query.query.bool.must.push(searchQuery);
        if (!props.noSuggest && false) {
            const suggest = {
                spelling: {
                    text: search,
                    term: {
                        field: "search_data.full_text"
                    }
                }
            };
            query["suggest"] = suggest;
        }
    }

    const category = options.category || "";
    if (category) {
        const categoryList = category.split(",").filter(v => !!v);
        if (categoryList.length > 0) {
            query.query.bool.filter.push({ "terms":  { "category.id_list": categoryList }});
        } else {
            query.query.bool.filter.push({ "term":  { "category.id_list": category }});
        }
    }

    if (ecommerceStoreGroupId && search && !props.searchInStoreGroup) {
        //query.query.bool.filter.push({ "term":  { "ecommerce_store.id_list": ecommerceStoreId }});
    }

    return dataSourceV2(`${props.path || "product_view_list"}?is_search=true&postprocess=${options.postprocess || props.postprocess || ecommerceStoreId}&query=${JSON.stringify(query)}`, {url: props.apiPath})
        .then(v=> {
            if (!v.message && v.item_list) {
                const itemList = v.item_list.map(data => {
                    const category_id_dict = data.category_id_dict || {};
                    const version = data.version || {};
                    const res = {...version, category_id_dict, attribute_set_id: data.attribute_set_id};
                    res[props.itemId || "product_id"] = data[props.itemId || "product_id"];

                    return res;
                });
                const facetData = {facetDataDict: {}, facetDataList: []};
                if (v.aggregations) {
                    ((((v.aggregations.general_facet || {}).agg_string_facet || {}).facet_name || {}).buckets || []).map((bucket, i) => {
                        const key = bucket.key;
                        const values = ((bucket.facet_value || {}).buckets || []).map(value => {
                            const keySplit = value.key.split("|");
                            return {...value, key: keySplit[1], name: keySplit[1], value: keySplit[0]};
                        });
                        facetData.facetDataDict[key] = values;
                        facetData.facetDataList.push(key);
                    });
                    Object.keys(v.aggregations).forEach(aggKey => {
                        if (aggKey.startsWith("special_facet_")) {
                            const key = aggKey.replace("special_facet_", "").replace("_filter", "");
                            const values = ((((v.aggregations[aggKey].agg_string_facet || {}).facet_name || {}).facet_value || {}).buckets || []).map(value => {
                                const keySplit = value.key.split("|");
                                return {...value, key: keySplit[1], name: keySplit[1], value: keySplit[0]};
                            });
                            facetData.facetDataDict[key] = values;
                        }
                    });
                }
                props.setItemData ? props.setItemData({itemList, itemTotal: v.size || 0, pageTotal: Math.ceil((v.size || 0) / pageSize), searchSynonymList: [], prevParams: location.search}) : null;
                props.setFacetData ? props.setFacetData(facetData) : null;
            } else {
                const searchSynonymList = [];
                if (v.suggest) {
                    ((v.suggest || {}).spelling || []).forEach(spell => {
                        (spell.options || []).forEach(option => {
                            searchSynonymList.push({name: option.text || ""});
                        });
                    });
                }
                props.setItemData ? props.setItemData({itemList: [], pageTotal: 0, itemTotal: 0, searchSynonymList}) : null;
                props.setFacetData ? props.setFacetData({facetDataDict: {}, facetDataList: []}) : null;
            }
            props.setLoading ? props.setLoading(false) : null;
            return v;
        });
};

const createSortQuery = (sort, ecommerceStoreId, options) => {
    options = options || {};
    if (options.isSearch) {
        return [
            {
                "_score": { "order": "desc" }
            }
        ];
    }
    const res = [];
    const stock = {
        "search_data.number_dynamic.value": {
            "missing": "_first",
            "mode": "avg",
            "order" : "asc",
            "nested": {
                "path": "search_data.number_dynamic",
                "filter": {
                    "term" : { "search_data.number_dynamic.name" : "is_not_in_stock|" + ecommerceStoreId }
                }
            }
        }
    };
    const pupular = {
        "search_data.number_dynamic.value": {
            "missing": "_last",
            "mode": "avg",
            "order" : "desc",
            "nested": {
                "path": "search_data.number_dynamic",
                "filter": {
                    "term" : { "search_data.number_dynamic.name" : "position_boost|" + ecommerceStoreId }
                }
            }
        }
    };
    if (!sort || sort === "popular") {
        //res.push(stock);
        //res.push(pupular);
        res.push({"search_data.name_sort": {"order" : "asc"}});
    } else if (sort === "created_at_asc") {
        //res.push(stock);
        res.push(
            {
                "search_data.created_at": {
                    "order" : "asc"
                }
            }
        );
    } else if (sort === "created_at_desc") {
        //res.push(stock);
        res.push(
            {
                "search_data.created_at": {
                    "order" : "desc"
                }
            }
        );
    } else if (sort === "price_asc") {
        //res.push(stock);
        res.push(
            {
                "search_data.number_dynamic.value": {
                    "missing": "_last",
                    "mode": "avg",
                    "order" : "asc",
                    "nested": {
                        "path": "search_data.number_dynamic",
                        "filter": {
                            "term" : { "search_data.number_dynamic.name" : "price|" + ecommerceStoreId }
                        }
                    }
                }
            }
        );
    } else if (sort === "price_desc") {
        //res.push(stock);
        res.push(
            {
                "search_data.number_dynamic.value": {
                    "missing": "_last",
                    "mode": "avg",
                    "order" : "desc",
                    "nested": {
                        "path": "search_data.number_dynamic",
                        "filter": {
                            "term" : { "search_data.number_dynamic.name" : "price|" + ecommerceStoreId }
                        }
                    }
                }
            }
        );
    } else if (sort === "discount_percent") {
        //res.push(stock);
        res.push(
            {
                "search_data.number_dynamic.value": {
                    "missing": "_last",
                    "mode": "avg",
                    "order" : "desc",
                    "nested": {
                        "path": "search_data.number_dynamic",
                        "filter": {
                            "term" : { "search_data.number_dynamic.name" : "discount_percent|" + ecommerceStoreId }
                        }
                    }
                }
            }
        );
        res.push(
            {
                "search_data.number_dynamic.value": {
                    "missing": "_last",
                    "mode": "avg",
                    "order" : "asc",
                    "nested": {
                        "path": "search_data.number_dynamic",
                        "filter": {
                            "term" : { "search_data.number_dynamic.name" : "price|" + ecommerceStoreId }
                        }
                    }
                }
            }
        );
    } else if (sort === "name") {
        //res.push(stock);
        res.push({"search_data.name_sort": {"order" : "asc"}});
    } else if (sort === "is_novelties") {
        //res.push(stock);
        res.push({"search_data.is_novelties": {"order" : "asc"}});
        res.push(pupular);
    } else if (sort === "is_bestseller") {
        //res.push(stock);
        res.push({"search_data.is_bestseller": {"order" : "asc"}});
        res.push(pupular);
    }
    return res;
};


export const onAddFilterTemplate = (props) => (key, value) => {
    let check = false;
    const changed = [];
    changed.push(key);
    const isFilterKey = key.endsWith("_filter");
    const filterSelectedDict = queryString.parse(props.location.search);
    Object.keys(filterSelectedDict).forEach(key_ => {
        if (key === "search" && (key_ === "page" || key_ === "category" || key_.endsWith("_filter"))) {
            changed.push(key_);
            delete filterSelectedDict[key_];
            return;
        }
        if (key === "category" && (key_ === "search" || key_.endsWith("_filter"))) {
            changed.push(key_);
            delete filterSelectedDict[key_];
            return;
        }
        if ((isFilterKey || key === "category") && key_ === "page") {
            changed.push(key_);
            delete filterSelectedDict[key_];
            return;
        }
        if (key_ === key) {
            check = true;
            if (isFilterKey) {
                let val = (filterSelectedDict[key_] || "").split(";");
                val.push(value);
                filterSelectedDict[key] = val.join(";");
                return;
            } else {
                filterSelectedDict[key] = value;
                return;
            }
        }
    });

    if (!check) {
        filterSelectedDict[key] = value;
    }

    props.dispatch(props.location.pathname + "?"+ queryString.stringify(filterSelectedDict));
    props.request ? props.request(filterSelectedDict) : null;
    props.setFilterSelectedDict ? props.setFilterSelectedDict(filterSelectedDict) : null;
    scrollTopIf(changed);
};

export const onDeleteFilterTemplate = (props) => (key, value, options) => {
    options = options || {};
    const changed = [];
    changed.push(key);
    const isFilterKey = key.endsWith("_filter");
    const filterSelectedDict = options.filterSelectedDict || queryString.parse(props.location.search);
    Object.keys(filterSelectedDict).forEach(key_ => {
        if (key === "category" && key_.endsWith("_filter")) {
            changed.push(key_);
            delete filterSelectedDict[key_];
            return;
        }
        if ((isFilterKey || key === "category") && key_ === "page") {
            changed.push(key_);
            delete filterSelectedDict[key_];
            return;
        }
        if (key_ === key) {
            if (isFilterKey && !options.hard) {
                const val = (filterSelectedDict[key_] || "").split(";").filter(value_ => value_ !== value).join(";");
                if (val) {
                    filterSelectedDict[key_] = val;
                    return;
                }
            }
            delete filterSelectedDict[key_];
            return;
        }
    });

    if (options.noActions) {
        return {changed, filterSelectedDict};
    }

    props.dispatch(props.location.pathname + "?"+ queryString.stringify(filterSelectedDict));
    props.request(filterSelectedDict);
    props.setFilterSelectedDict(filterSelectedDict);
    scrollTopIf(changed);
};

export const onDeleteFilterManyTemplate = (props) => (keyList, valueList, options) => {
    options = options || {};
    let filterSelectedDictFinal = {};
    let changedFinal = [];
    keyList.forEach((key, i) => {
        const {changed, filterSelectedDict} = onDeleteFilter(key, valueList[i], {...options, noActions: true, filterSelectedDict: i === 0 ? undefined : filterSelectedDictFinal});
        filterSelectedDictFinal = {...filterSelectedDictFinal, ...filterSelectedDict};
        changedFinal = changedFinal.concat(changed);
    });
    props.dispatch(props.location.pathname + "?"+ queryString.stringify(filterSelectedDictFinal));
    props.request(filterSelectedDictFinal);
    props.setFilterSelectedDict(filterSelectedDictFinal);
    scrollTopIf(changedFinal);
};

export const onResetFacetFiltersTemplate = (props) => (options) => {
    options = options || {};
    const keyList = [];
    const valueList = [];

    Object.keys(props.location.search).forEach(key => {
        let condition = key.endsWith("_filter");
        if (options.resetAll) {
            condition = condition || key === "category" || key === "page" || key === "search";
        }
        if (condition) {
            keyList.push(key);
            valueList.push(undefined);
        }
    });
    props.onDeleteFilterMany(keyList, valueList, {hard: true});
};

export const onLocationChangeTemplate = (props) => () => {
    if (!props.loading) {
        const filterSelectedDict = props.filterSelectedDict;
        const filterSelectedDict_ = queryString.parse(props.location.search);
        let changed = false;
        Object.keys(filterSelectedDict).map(key => {
            if (changed) {
                return;
            }
            if (key.endsWith("_filter") || key === "search" || key === "category" || key === "page") {
                if (filterSelectedDict_[key] !== filterSelectedDict[key]) {
                    changed = true;
                }
            }
        });
        if (!changed) {
            Object.keys(filterSelectedDict_).map(key => {
                if (changed) {
                    return;
                }
                if (key.endsWith("_filter") || key === "search" || key === "category" || key === "page") {
                    if (filterSelectedDict_[key] !== filterSelectedDict[key]) {
                        changed = true;
                    }
                }
            });
        }
        if (!changed) {
            return;
        }
        if (props.location.search) {
            props.setFilterSelectedDict(filterSelectedDict_);
        }
        props.request(filterSelectedDict_);
        window.scrollTo(0, 0);
    }
};


export const onDescriptionDataTemplate = (props) => (id, type, options) => {
    let path = "";
    if (!type || type === "category") {
        path = `product_category_get?product_category_id=${id}`;
    } else if (type === "manufacturer") {
    }
    return dataSourceV2(path, {url: props.apiPath})
        .then(v=> {
            if (!v.message && v.item) {
                props.setDescriptionData(v.item);
            }
        });
};
